<template>
  <div class="todo" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="top">
        <div class="title">계획 {{ state.mode === "insert" ? "추가" : state.mode === "update" ? "수정" : "상세" }}</div>
        <button class="remove btn" title="삭제" @click="remove(modalParams.todoSeq)" v-if="state.mode !== 'insert' && $store.state.account.memberSeq === state.ownMemberSeq">
          <i class="fa fa-trash"></i>
        </button>
      </div>
      <div class="form">
        <div class="sections tabs">
          <button class="btn section" v-for="(c, idx) in sections" :key="idx" :class="{selected: state.form.section === c.name}" @click="setCategory(c.name)" :disabled="!isOwner()">
            {{ c.title }}
          </button>
        </div>
        <div class="body">
          <div class="inputs">
            <div class="wrapper row">
              <div class="form-group subject col-8 pr-0">
                <label :for="`${component.name}Subject`">
                  <span class="color-purple">*</span>
                  <span class="subject"> 제목</span>
                </label>
                <input :id="`${component.name}Subject`" v-model="state.form.title" type="text" class="form-control" @keyup.enter="submit()"
                       placeholder="ex) 구글애즈에 캠페인 게시" autocomplete="off" @focus="edit()" :readonly="state.mode === 'read'" :disabled="!isOwner()"/>
              </div>
              <div class="form-group col-4 charge">
                <label :for="`${component.name}Charge`">
                  <span class="subject">담당</span>
                </label>
                <select class="form-control" v-model="state.form.memberSeq" @focus="edit()" tabindex="-1" :disabled="!isOwner()">
                  <option v-for="(m, idx) in modalParams.members" :key="idx" :value="m.memberSeq" :selected="m.memberSeq === state.ownMemberSeq">{{ m.memberName }}</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label :for="`${component.name}Content`">
                <span class="subject"> 내용</span>
              </label>
              <div class="text div" v-html="$lib.getUrlToAnchorReplaced(state.form.content)" @click="edit(`${component.name}Content`, $event, true)" v-if="state.mode === 'read'"></div>
              <textarea :id="`${component.name}Content`" v-model="state.form.content" class="text form-control border-focus-purple"
                        @keyup.ctrl.enter="submit($event)" placeholder="ex) 가족 프로젝트 홍보 동영상 업로드 및 구글애즈에 캠페인 게시"
                        :readonly="state.mode === 'read'" v-else></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action" v-if="!state.loaded || isOwner()">
      <button class="btn btn-purple btn-block" @click="edit(undefined, undefined, true)" v-if="state.mode === 'read'">수정하기</button>
      <button class="btn btn-purple btn-block" @click="submit()" v-else>저장하기</button>
    </div>
  </div>
</template>
c
<script>
import {defineComponent, nextTick, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import storage from "@/scripts/storage";

function Component(initialize) {
  this.name = "modalTodo";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (modalParams.type === "add") {
        state.mode = "insert";
      }

      store.commit("tuneModal", {component, size: "sm"});
      load();
    });

    const state = reactive({
      loaded: true,
      mode: "read",
      ownMemberSeq: "",
      form: {
        title: "",
        content: "",
        section: "",
        memberSeq: "",
        finished: false,
      },
    });

    const modalParams = store.getters.modalParams(component);

    const sections = [{
      name: "current",
      title: "금주 계획"
    }, {
      name: "shortTerm",
      title: "단기 계획"
    }, {
      name: "longTerm",
      title: "장기 계획"
    }, {
      name: "finished",
      title: "처리 완료"
    }, {
      name: "hold",
      title: "보류"
    }];

    const isOwner = () => {
      return modalParams.type === "add" || state.ownMemberSeq === store.state.account.memberSeq;
    };

    const setCategory = (category) => {
      if (category === state.form.section) {
        return;
      }

      edit();
      state.form.section = category;
    };

    const load = () => {
      if (modalParams.type === "add") {
        state.form.section = storage.get("session", "selectedCategory") || "current";
        state.ownMemberSeq = store.state.account.memberSeq;
        state.form.memberSeq = state.ownMemberSeq;
        return;
      }

      state.loaded = false;
      http.get(`/api/admin/todos/${modalParams.todoSeq}`).then(({data}) => {
        state.loaded = true;
        state.ownMemberSeq = data.body.memberSeq;
        state.form.section = data.body.finished ? "finished" : data.body.category;
        state.form.title = data.body.title;
        state.form.content = data.body.content;
        state.form.memberSeq = state.ownMemberSeq;
      });
    };

    const edit = (id, e, focus) => {
      if ((e && e.target && e.target.tagName === "A" && e.target.href) || !isOwner() || ["insert", "update"].includes(state.mode)) {
        return;
      }

      state.mode = "update";

      focus && nextTick(() => {
        document.getElementById(id || `${component.name}Subject`)?.focus();
      });
    };

    const submit = () => {
      if (!isOwner()) {
        return;
      }

      if (!state.form.title) {
        document.querySelector(`${component.name}Title`)?.focus();
        return store.commit("setSwingMessage", "제목을 입력해주세요.");
      }

      const onSuccess = () => {
        store.commit("setSwingMessage", "저장하였습니다.");
        close();
      };

      const args = lib.getRenewed(state.form);
      args.finished = args.section === "finished";
      delete args.section;

      if (modalParams.type === "update") {
        if (!args.finished) {
          args.category = state.form.section;
        }

        http.put(`/api/admin/todos/${modalParams.todoSeq}`, args).then(onSuccess);
      } else {
        args.category = args.finished ? "current" : state.form.section;
        http.post(`/api/admin/todos`, args).then(() => {
          storage.set("session", "selectedCategory", `${state.form.section}`);
          onSuccess();
        });
      }
    };

    const close = () => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    const remove = (todoSeq) => {
      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete(`/api/admin/todos/${todoSeq}`).then(() => {
            store.commit("setSwingMessage", "삭제하였습니다.");
            close();
          });
        }
      });
    };

    onMounted(() => {
      modalParams.type === "add" && document.getElementById(`${component.name}Subject`)?.focus();
    });

    return {component, state, modalParams, sections, edit, submit, close, remove, isOwner, setCategory};
  }
});
</script>

<style lang="scss" scoped>
.todo {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .subject {
      font-size: $px14;
    }
  }

  .top {
    position: relative;
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }

    .remove {
      position: absolute;
      top: 0;
      right: 0;
      padding: $px9 $px5;
      margin: $px-5;
    }
  }

  .form {
    .sections {
      position: relative;
      display: flex;
      justify-content: space-between;
      white-space: nowrap;

      .btn {
        font-size: $px12;
        padding: $px13;
        border-radius: $px4;
        max-width: calc(100% / 5);
        opacity: 1;
        border: $px1 solid $colorBorder;

        &.selected {
          border-color: $colorPurple;
          color: $colorPurpleActive
        }

        &:not(.selected) {
          &:hover {
            background: #f7f7f7;
          }
        }
      }
    }

    p {
      line-height: inherit;
    }

    h4, p {
      margin-bottom: 0;
    }
  }

  .body {
    .inputs {
      .form-group {
        padding-top: $px25;
        margin-bottom: 0;

        input, .text, select {
          background-color: #fff;
          font-size: $px13;
          transition: none;
        }

        input[readonly] {
          cursor: default;
        }

        > select {
          height: $px45;
        }

        .text {
          padding: $px15;
          border: $px1 solid $colorBorder;
          border-radius: $px4;
          min-height: $px220;
          white-space: pre-line;
          line-height: 1.6;

          &::v-deep {
            a {
              color: $colorAnchor;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          &.div {
            max-height: $px220;
            overflow: auto;
          }
        }

        &.charge {
          line-height: 1.3;
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;

      &.remove {
        background: $colorSecondary;
        border-color: $colorSecondary;
        color: #fff;

        &:hover {
          background: $colorSecondaryActive;
          border-color: $colorSecondaryActive;
        }
      }
    }
  }

  &.skeleton {
    .top .remove i {
      @include skeleton;
    }

    .form .sections .btn {
      @include skeleton;
    }

    .body .inputs .form-group {
      input, .text, select {
        @include skeleton;
      }
    }

    .action .btn {
      @include skeleton;
    }
  }
}
</style>